@import '../../scss/utils/getter.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/typography.scss';
@import '../../scss/var/transitions.scss';

#NotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 12em;
    margin-bottom: 40px;
    font-weight: 700;
    letter-spacing: 0.5em;
    margin-right: -0.5em;
  }
  h2 {
    &:before {
      display: none;
    }
    &:first-letter {
      text-transform: uppercase;
    }
    text-transform: lowercase;
    padding-left: 0;
  }
}
