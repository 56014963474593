@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/transitions.scss';
@import '../../scss/var/typography.scss';

header {
  top: 0;
  left: 0;
  width: 100%;
  background: $color-white;
  z-index: 1;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  .grid-container {
    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      line-height: 0;
    }
    #logo {
      padding: 8px;
      font-family: $font-cursive;
      height: 1.7em;
      color: $color-blue;
    }
  }
}
