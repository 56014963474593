@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/transitions.scss';
@import '../../scss/var/typography.scss';

#prix {
  p {
    font-size: 21px;
  }
  img {
    width: 100%;
  }
  .laureats__list {
    h3 {
      margin-top: 2em;
      padding-bottom: 0em;
    }
    .laureats__profile {
      a {
        text-align: center;
        .laureats__profile__pic {
          text-align: center;
          object-fit: cover;
          border-radius: 100%;
          width: 99%;
        }
        .laureats__profile__name {
          font-weight: 600;
        }
        .laureats__profile__year {
        }
      }
    }
  }
  @include xs {
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9 {
      grid-column: 12 span;
    }
    .col-2 {
      grid-column: 6 span;
    }
  }
  @include md {
    @for $column-span from 1 through 12 {
      .col-#{$column-span} {
        @include grid-item($column-span);
      }
    }
  }
}
