@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap");
/*Colors*/
/* Utils*/
/* Main*/
/* Gradients*/
/* Finger (Mouse animation) */
@keyframes finger {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
/* Menu modal Mobile animation */
@keyframes menuOpen {
  0% {
    bottom: -100vh;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/*/
/*  v2.0 | 20110126*/
/*  License: none (public domain)*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

.grid-container {
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-auto-rows: min-content;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}
@media (min-width: 320px) {
  .grid-container {
    max-width: 768px;
  }
}
@media (min-width: 768px) {
  .grid-container {
    max-width: 1000px;
  }
}
@media (min-width: 1000px) {
  .grid-container {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .grid-container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .grid-container {
    grid-template-columns: repeat(12, 1fr);
    max-width: 1400px;
  }
}

.col-1 {
  grid-column: 1 span;
}

.col-2 {
  grid-column: 2 span;
}

.col-3 {
  grid-column: 3 span;
}

.col-4 {
  grid-column: 4 span;
}

.col-5 {
  grid-column: 5 span;
}

.col-6 {
  grid-column: 6 span;
}

.col-7 {
  grid-column: 7 span;
}

.col-8 {
  grid-column: 8 span;
}

.col-9 {
  grid-column: 9 span;
}

.col-10 {
  grid-column: 10 span;
}

.col-11 {
  grid-column: 11 span;
}

.col-12 {
  grid-column: 12 span;
}

/*Colors*/
/* Utils*/
/* Main*/
/* Gradients*/
html {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  background-color: white;
  color: black;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

div:focus {
  outline: none;
  user-select: none;
}

#root {
  overflow-x: hidden;
}

section {
  width: 100vw;
}

h2 {
  font-family: "Dancing Script", cursive;
  color: #561bac;
  padding: 0.7em;
  font-size: 3em;
  text-align: center;
}

h3 {
  font-size: 2em;
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
p {
  line-height: 2em;
}

a {
  background: transparent;
  transition: background 250ms ease-in-out;
  color: black;
}