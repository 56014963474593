@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/typography.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';

#home {
  position: relative;
  h2 {
    text-align: center;
  }
  p {
    font-size: 21px;
    text-align: center;
  }
  .col-4 {
    display: flex;
    align-items: center;
    p.left {
      text-align: left;
    }
    p.right {
      text-align: right;
    }
    img {
      width: 100%;
      text-align: center;
    }
  }
  .last {
    width: 60%;
    margin: 0 auto;
  }
  @include xs {
    .col-4 {
      grid-column: 12 span;
    }
  }
  @include md {
    .col-4 {
      grid-column: 4 span;
    }
  }
}
