h1 {
}
h2 {
  font-family: $font-cursive;
  color: $color-blue;
  padding: 0.7em;
  font-size: 3em;
  text-align: center;
}
h3 {
  font-size: 2em;
}
h4 {
}
p {
  font-family: $font-body;
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
p {
  line-height: 2em;
}

em {
}

a {
  background: transparent;
  transition: background 250ms ease-in-out;
  color: $color-black;
  &:hover {
  }
}
