$animation-map: (
  speed: (
    controllers: 100ms,
    popovers-in: 150ms,
    popovers-out: 75ms,
    default-in: 250ms,
    default-out: 200ms,
  ),
  ease: (
    default: cubic-bezier(0.4, 0, 0.2, 1),
    in: cubic-bezier(0, 0, 0.2, 1),
    out: cubic-bezier(0.4, 0, 1, 1),
  ),
);

@function get-animation($keys...) {
  $map: $animation-map;
  @each $key in $keys {
    $map: map-get-strict($map, $key);
  }
  @return $map;
}

/* Finger (Mouse animation) */
@keyframes finger {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

/* Menu modal Mobile animation */
@keyframes menuOpen {
  0% {
    bottom: -100vh;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
