/*Colors*/
/* Utils*/
$color-black: rgb(0, 0, 0);
$color-grey-mid: rgb(133, 135, 134);
$color-white: rgb(255, 255, 255);

/* Main*/
$color-blue: rgb(86, 27, 172);

/* Gradients*/
