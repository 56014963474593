@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/transitions.scss';
@import '../../scss/var/typography.scss';

#apport {
  p {
    font-size: 21px;
  }
  img {
    width: 100%;
  }
  @include xs {
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9 {
      grid-column: 12 span;
    }
  }
  @include md {
    @for $column-span from 1 through 12 {
      .col-#{$column-span} {
        @include grid-item($column-span);
      }
    }
  }
}
