@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/typography.scss';

footer {
  text-align: center;
  display: block;
  img {
    margin-top: 5em;
    width: auto;
  }
  a {
    display: block;
    margin: 3vh 0;
    font-family: $font-body;
    font-size: 0.75em;
    color: grey;
  }
}
