@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/transitions.scss';
@import '../../scss/var/typography.scss';

.nav {
  @include xs {
    display: flex;
    width: 300vw;
    bottom: -100vh;
    left: -100vw;
    flex-direction: column;
    position: fixed;
    padding-top: 1.5em;
    padding-bottom: 6em;
    border-radius: 100% 100% 0 0;
    &-open {
      border-top: 1px solid $color-blue;
      z-index: 100;
      background: $color-white;
      animation: menuOpen 250ms ease-out forwards;
    }
  }
  @include md {
    position: relative;
    bottom: 0;
    left: 0;
    background: none;
    box-shadow: none;
    flex-direction: row;
    border-radius: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 700px;
    padding: 0;
    &-open {
      animation: none;
    }
  }
  .nav__item {
    box-sizing: border-box;
    margin: 0 0.75em;
    padding: 0.25em;
    font-family: $font-body;
    font-weight: 500;
    font-size: 0.75em;
    @include xs {
      align-items: center;
      display: flex;
      font-size: 18px;
      justify-content: center;
      line-height: 4em;
      opacity: 0.8;
      &-active {
        color: $color-blue;
      }
    }
    @include md {
      color: $color-black;
      display: inline-flex;
      font-size: 14px;
      opacity: 1;
      &-active {
        border-bottom: 3px solid $color-blue;
      }
    }
  }
}
