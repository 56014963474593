@import '../../scss/utils/getter.scss';
@import '../../scss/var/typography.scss';
@import '../../scss/var/colors.scss';

#laureat {
  .profile {
    padding-top: 1em;
    text-align: center;
    .profile__pic {
      width: 300px;
      height: 300px;
      border-radius: 100%;
      object-fit: cover;
    }
    .profile__name {
      padding-bottom: 0;
    }
    .profile__info {
      font-size: 1.75em;
      display: inline-flex;
    }
    .profile__press {
      max-width: 100%;
    }
  }
}
