@import 'utils/mixins.scss';
@import 'var/colors.scss';
@import 'var/screensizes.scss';

html {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  background-color: $color-white;
  color: $color-black;
  font-family: $font-body;
  font-size: 18px;
}

div:focus {
  outline: none;
  user-select: none;
}

#root {
  overflow-x: hidden;
}
