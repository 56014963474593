$col-count: 12;

.grid-container {
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-auto-rows: min-content;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  @include xs {
    max-width: $screen-sm-min;
  }
  @include sm {
    max-width: $screen-md-min;
  }
  @include md {
    max-width: $screen-md-min;
  }
  @include lg {
    max-width: $screen-lg-min;
  }
  @include xl {
    grid-template-columns: repeat($col-count, 1fr);
    max-width: $screen-xl-min;
  }
}

@for $column-span from 1 through $col-count {
  .col-#{$column-span} {
    @include grid-item($column-span);
  }
}
